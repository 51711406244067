import gql from 'graphql-tag'

const GET_JOURNEYS_QUERY = gql`
  query Journeys($id: ID!, $dateFrom: DateTime, $dateTo: DateTime) {
    journeys(where: {
      AND: [{
        trip: {
          id: $id
        }
      },
      {
        date_gt: $dateFrom
      },
      {
        date_lt: $dateTo
      }]
    },
    orderBy: date_ASC
  ) {
      edges {
        node {
          id
          date
          trip {
            id
            title
          }
          tickets {
            title
            cost
          }
          seatsAvailable
          seatsBooked
          seatsRemaining
          isAvailable
        }
      }
    }
  }
`

const GET_FIRST_JOURNEY_DATE = gql`
  query GetFirstJourneyDate($id: ID!, $dateFrom: DateTime) {
    firstJourney: journeys(where: {
      AND: [{
        trip: {
          id: $id
        }
      },
      {
        date_gt: $dateFrom
      }]
    },
    first: 1,
    orderBy: date_ASC
  ) {
      edges {
        node {
          id
          date
        }
      }
    }
  }
`

export {
  GET_JOURNEYS_QUERY,
  GET_FIRST_JOURNEY_DATE
}