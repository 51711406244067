import React from 'react'

import TourInfo from './TourInfo'
import TourCalendar from './TourCalendar'

const TourSidebar = ({ tour }) => {
  console.log(tour);
  
  return (
  <div className="mx-auto w-full md:w-3/5 lg:w-2/5 lg:ml-8 mt-0 mb-8 lg:mb-16 lg:mb-0 px-4">
    {tour.bookingId && <TourCalendar booking={tour.bookingId} /> }
    {!tour.bookingId && <TourInfo tour={tour} /> }
  </div>
)}

export default TourSidebar