import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

const CustomDatePicker = ({ journeys, selectedDate, availableDates, handleChange, handleMonthChange }) => (
  <DatePicker
    inline
    selected={selectedDate}
    onChange={handleChange}
    openToDate={availableDates[0]}
    includeDates={availableDates}
    calendarClassName="tour-calendar"
    onMonthChange={handleMonthChange}
    minDate={moment()}
    maxDate={moment().add(12, 'months')}
    forceShowMonthNavigation={true}
    dayClassName={date => {
      let j = null
      if (journeys) {
        j = journeys.find(({ node }) => moment(node.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));
      }
      if (j && (j.node.seatsAvailable <= j.node.seatsBooked)) return 'sold-out'
      if (j && (j.node.seatsAvailable - j.node.seatsBooked < 10)) return 'limited'
      return ''
    }}
    renderDayContents={(dayOfMonth) => <span>{dayOfMonth}</span>}
  />
)

const MemoizedCustomDatePicker = React.memo(CustomDatePicker, (prev, next) => prev.selectedDate === next.selectedDate);

export default MemoizedCustomDatePicker