import React from 'react'
import { Link } from 'gatsby'

export const TourSummary = ({ tour }) => (
  <Link to={`/coach-tours/${tour.slug}`} title={tour.title} className="md:w-1/2 lg:w-1/3 flex items-center flex-col mb-4 lg:mb-0 md:px-6 no-underline mb-16">
    <img src={tour.featuredImage.resize.src} alt={tour.featuredImage.title} className="w-full block mb-6 shadow" />
    <h3 className="w-full mb-4 text-left text-black text-lg">{tour.title}</h3>
    <p className="text-gray-500">{tour.shortDescription.shortDescription}</p>
  </Link>
)

const TourItems = ({ tours }) => (
  <div className="flex flex-wrap justify-center md:mx-4">
    {tours && tours.map((tour, i) => {
      if (tour.node) {
        return (
          <TourSummary tour={tour.node} key={i} />
        )
      }
      return (
        <TourSummary tour={tour} key={i} />
      )
    })
    }
  </div>
)

export default TourItems