import React, { useState, useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import moment from 'moment'

import { GET_JOURNEYS_QUERY, GET_FIRST_JOURNEY_DATE } from '../queries'

import Loader from '../../global/Loader'
import { useIsMounted } from '../../global/customHooks';
import TourTickets from './TourTickets';
import { useStatus } from '../../global/customHooks';
import DatePicker from './DatePicker'
import { ADD_ITEM_TO_CART, GET_CART } from '../../cart/queries';
import { navigate } from 'gatsby';

const DatePickerWithData = ({ booking, selectedDate, selectedJourney, handleChange, date, handleJourneysUpdate, addToCart }) => {
  const [dateFrom, setDateFrom] = useState(date)
  const { loading, error, data } = useQuery(GET_JOURNEYS_QUERY, {
    variables: {
      id: booking,
      dateFrom,
      dateTo: moment(dateFrom).endOf('month').toJSON(),
    }, 
    onCompleted: d => {
      handleJourneysUpdate(d.journeys.edges)
    },
    fetchPolicy: "network-only"
  });

  const handleMonthChange = e => {
    if (moment(e).isSameOrBefore(moment(), 'month')) {
      setDateFrom(moment().startOf('day').toJSON())
    } else {
      setDateFrom(moment(e).startOf('month').toJSON())
    }
  }

  if (loading) return <Loader />
  if (error) return <div>Error!</div>
  return (
    <>
      <DatePicker 
        journeys={data.journeys.edges}
        selectedDate={selectedDate}
        availableDates={data.journeys.edges.map(({node}) => moment(node.date).toDate())}
        handleChange={handleChange}
        handleMonthChange={handleMonthChange}
      />
      {selectedDate && selectedJourney && (selectedJourney.seatsAvailable > selectedJourney.seatsBooked) && (
        <TourTickets selectedJourney={selectedJourney} addToCart={addToCart} />
      )}
    </>
  )
}


const TourCalendar = ({ booking }) => {
  
  const client = useApolloClient();
  const [journeys, setJourneys] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState(null);

  useEffect(() => {
    const index = journeys.findIndex(({node}) => {
      return moment(node.date).startOf('day').isSame(moment(selectedDate).startOf('day'))
    })
    if (index !== -1) {
      setSelectedJourney({
        ...journeys[index].node,
        tickets: journeys[index].node.tickets.map(ticket => {
          return {
            ...ticket,
            quantity: 0
          }
        })
      })
    }
  }, [journeys, selectedDate])

  useEffect(() => {
    setSelectedDate(null)
  }, [journeys])

  useEffect(() => {
    console.log(selectedJourney);
  }, [selectedJourney])

  const handleChange = (e) => {
    setSelectedDate(e)
  }

  const addToCart = async values => {
    await client.mutate({
      mutation: ADD_ITEM_TO_CART,
      variables: {
        item: {
          id: selectedJourney.id,
          title: selectedJourney.trip.title,
          date: selectedJourney.date,
          tickets: values.tickets
        }
      },
      refetchQueries: [{
        query: GET_CART
      }]
    })
    setSelectedJourney(null)
    navigate('/cart', {
      state: {
        status: {
          code: 'SUCCESS',
          message: 'Tickets successfully added to cart!'
        }
      }
    })
  }

  const handleJourneysUpdate = journeys => {
    setJourneys(journeys)
  }

  const dateFrom = moment().startOf('day').toJSON()
  
  const { loading, error, data } = useQuery(GET_FIRST_JOURNEY_DATE, {
    variables: {
      id: booking,
      dateFrom
    }
  });
  
  if (loading) return <Loader />
  if (error) return <div>Error!</div>
  
  return (
    <>
      <div className="shadow p-4 mb-0 -mt-48 md:-mt-40 lg:-mt-64 z-40 bg-white">
        <DatePickerWithData
          booking={booking}
          selectedDate={selectedDate}
          handleChange={handleChange}
          date={moment(data.firstJourney.edges[0].node.date).startOf('day').toJSON()}
          handleJourneysUpdate={handleJourneysUpdate}
          addToCart={addToCart}
          selectedJourney={selectedJourney}
        />
      </div>
      <div className="flex justify-center flex-wrap mt-6 px-8">
        <div className="flex items-center text-sm px-2 mb-2"><span className="inline-block rounded-full h-4 w-4 bg-gray-200 mr-2"></span>Available</div>
        <div className="flex items-center text-sm px-2 mb-2"><span className="inline-block rounded-full h-4 w-4 bg-orange-300 mr-2"></span>Limited Availability</div>
        <div className="flex items-center text-sm px-2 mb-2"><span className="inline-block rounded-full h-4 w-4 bg-red-500 mr-2"></span>Sold Out</div>
      </div>
    </>
  )
}

export default TourCalendar