import React from 'react'
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';

const TicketError = ({children}) => {
  if (typeof children === 'string') { return <div className="w-full mb-4 text-xs block bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded-sm relative" role="alert">{children}</div> }
  return null
}
const TicketArrayErrors = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <div className="w-full mb-4 text-xs block bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded-sm relative" role="alert">{error}</div> : null;
    }}
  </Field>
);

const Ticket = ({ ticket, index, max }) => (
  <div className="py-2 flex flex-wrap items-center">
    <TicketArrayErrors name={`tickets[${index}].quantity`} />
    <div>
      <h4 className="font-bolder normal-case text-lg">{ticket.title}</h4>
      <p className="text-gray-400 text-sm">£{`${ticket.cost.toFixed(2)}`}</p>
    </div>
    <div className="ml-auto">
      <div className="flex items-center">
        <Field name={`tickets[${index}].quantity`}>
          {({ form: { setFieldValue, setFieldTouched } }) => (
            <button type="button" className="inline-block text-green-500 w-6 text-lg" onClick={() => {
                if ((ticket.quantity - 1) >= 0) {
                  setFieldValue(`tickets[${index}].quantity`, ticket.quantity - 1);
                  setFieldTouched(`tickets[${index}].quantity`);
                }
              }}
            >
              –
            </button>
          )}
        </Field>
        <Field name={`tickets[${index}].quantity`} type="number" min="0" max={max} className="counter appearance-none w-8 p-1 text-center text-xs md:text-sm mx-1 rounded-sm border border-gray-200" />
        <Field name={`tickets[${index}].quantity`}>
          {({ form: { setFieldValue, setFieldTouched } }) => (
            <button type="button" className="inline-block text-green-500 w-6 text-lg" onClick={() => {setFieldValue(`tickets[${index}].quantity`, ticket.quantity + 1); setFieldTouched(`tickets[${index}].quantity`);}}>
              +
            </button>
          )}
        </Field>
      </div>
    </div>
  </div>
)

const Tickets = ({ tickets, max }) => {
  if (tickets.length === 0) return <h4 className="mt-4 text-center text-gray-400 normal-case text-base">No Tickets Available</h4>
  return (
    <FieldArray
      name="tickets"
      render={() => (
        <>
          <ErrorMessage name={`tickets`} component={TicketError} />
          {tickets.map((ticket, index) => (
            <Ticket key={`tickets[${index}]`} ticket={ticket} index={index} max={max} />
          ))}
        </>
      )}
    />
  )
}

const TourTickets = ({ selectedJourney, addToCart }) => {

  const schema = Yup.object().shape({
    tickets: Yup.array()
      .of(
        Yup.object().shape({
          quantity: Yup.number()
            .typeError('Ticket quantity must be a number')
            .min(0, 'Ticket quantity must be greater than ${min}.')
            .required('Required')
        })
      )
      .test({
        name: 'max',
        test: values => {

          const count = values.reduce((count, t) => {
            if (t.quantity) {
              return count + t.quantity
            }
            return count + 0
          }, 0)
          
          return (selectedJourney.seatsRemaining - count) >= 0
        },
        message: `Not enough seats remaining.`
      })
  })

  return (
    <Formik
      initialValues={{ tickets: selectedJourney.tickets }}
      validationSchema={schema}
      onSubmit={addToCart}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <div className="px-4 py-4 border-t mt-4 border-gray-100">
            <Tickets tickets={values.tickets} max={selectedJourney.seatsRemaining} />
          </div>
          <button
            type="submit"
            disabled={isSubmitting || !isValid}
            className="ml-4 mb-4 btn btn-green cursor-pointer"
            >
            Add to Cart
          </button> 
        </Form>
      )}
    </Formik>
  )
}

export default TourTickets