import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/global/SEO'
import Hero, { HeroInfo } from '../components/global/Hero'
import { H2 } from '../components/global/Headings'
import { Section } from '../components/global/Section'
import TourItems from '../components/tours/TourItems'
import TourSidebar from '../components/tours/TourSidebar'
import RichText from '../components/global/RichText'

const TourPage = ({ data }) => {
  const { contentfulTour: tour, site } = data;
  
  return (
  <Layout>
    <SEO title={tour.seoTitle} description={tour.seoDescription} image={tour.seoSocialMediaImage && tour.seoSocialMediaImage.resize.src} />
    <Helmet>
      <meta property="og:type" content="og:product" />
      <meta property="og:title" content={tour.title} />
      <meta property="og:description" content={tour.shortDescription.shortDescription} />
      <meta property="og:url" content={`${site.siteMetadata.siteUrl}/coach-tours/${tour.slug}/`} />
      <meta property="og:image" content={`https:${tour.featuredImage.fixed.src}`} />
      <meta property="product:brand" content="Tours Ireland" />
      <meta property="product:availability" content="in stock" />
      <meta property="product:condition" content="new" />
      <meta property="product:price:amount" content={tour.priceFrom} />
      <meta property="product:price:currency" content="GBP" />
      <meta property="product:retailer_item_id" content={`${tour.bookingId}`} />
      <script type="application/ld+json">
      {`
        {
        "@context": "https://schema.org",
        "@type": "Product",
        "productID": "${tour.bookingId}",
        "name": "${tour.title}",
        "description": "${tour.shortDescription.shortDescription}",
        "url": "${site.siteMetadata.siteUrl}/coach-tours/${tour.slug}/",
        "image": "https:${tour.featuredImage.fixed.src}",
        "brand": "Tours Ireland",
        "offers":[{
          "@type": "Offer",
          "price": "${tour.priceFrom}",
          "priceCurrency": "GBP",
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock"
          }
        ]}
      `}
      </script>
    </Helmet>
    <Hero image={tour.featuredImage.fixed.src} className={`${tour.bookingId && `pb-40`} md:pb-0`}>
      <HeroInfo title={tour.title}>
        <p className="text-white mb-6 max-w-md text-shadow font-light">{tour.shortDescription.shortDescription}</p>
        {tour.locations && <p className="text-white mb-6 max-w-md text-shadow font-bold">{tour.locations.map((location, index) => <Fragment key={index}>{location.title}{(index + 1 !== tour.locations.length) && ` • `}</Fragment>)}</p>}
      </HeroInfo>
    </Hero>
    <Section className={tour.bookingId && `pt-12`}>
      <div className="flex flex-col-reverse lg:flex-row pt-0">
        <div className="lg:w-3/5 md:mr-8">
          {tour.bookingId &&
            <div className="mb-10">
              <ul className="flex justify-around lg:justify-start">
                <li className="flex lg:flex-1 flex-col uppercase text-xs text-gray-400 font-bold">From <span className="text-2xl text-green-500 font-normal">£{tour.priceFrom}</span></li>
                <li className="flex lg:flex-1 flex-col uppercase text-xs text-gray-400 font-bold">Duration <span className="text-2xl text-green-500 font-normal">{tour.duration}<span className="normal-case text-base">hrs</span></span></li>
                <li className="flex lg:flex-1 flex-col uppercase text-xs text-gray-400 font-bold">Locations <span className="text-2xl text-green-500 font-normal">{tour.locations.length}</span></li>
              </ul>
            </div>
          }
          <RichText json={tour.longDescription.json} />
        </div>
        <TourSidebar tour={tour} />
      </div>
    </Section>
    <Section>
      <H2 icon>{tour.title} Itinerary</H2>
      <div className="md:w-3/5 mx-auto">
        <RichText json={tour.itinerary.json} />
      </div>
    </Section>
    {tour.relatedTours &&
      <Section>
        <H2 icon>Other Popular Tours from Belfast</H2>
        <TourItems tours={tour.relatedTours} />
      </Section>
    }
  </Layout>
)}

export default TourPage

export const query = graphql`
  query getSingleTourQuery($id: String!)	{
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulTour(id: { eq: $id }) {
      id
      title
      slug
      featuredImage {
        fixed(width: 1600) {
          src
        }
      }
      priceFrom
      pickUp
      dropOff
      locations {
        title
      }
      duration
      tickets {
        ticketType
        cost
      }
      shortDescription {
        shortDescription
      }
      longDescription {
        json
      }
      itinerary {
        json
      }
      relatedTours {
        title
        slug
        featuredImage {
          resize(width: 450, height: 300) {
            src
          }
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
        shortDescription {
          shortDescription
        }
      }
      seoTitle
      seoDescription
      seoSocialMediaImage {
        resize(width: 1200, height: 630) {
          src
        }
      }
      bookingId
    }
  }
`
