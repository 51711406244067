import React from 'react'

const TicketType = ({ ticket }) => (
  <>
    <dt className="w-1/2 font-bold border-b border-gray-200 py-3">{ticket.ticketType}:</dt>
    <dd className="w-1/2 border-b border-gray-200 py-3 text-right">£{ticket.cost.toFixed(2)}</dd>
  </>
)

const TourInfo = ({ tour }) => (
  <>
    <div className="pb-8">
      <h3 className="pb-4 font-bold text-lg">Tickets</h3>
      <dl className="flex flex-wrap text-sm">
        {tour.tickets && tour.tickets.map((ticket, index) => (
          <TicketType ticket={ticket} key={`ticket-${index}`} />
        ))}
      </dl>
    </div>
    {(tour.pickUp || tour.dropOff || (tour.locations && (tour.locations.length !== 0)) || tour.duration) &&
      <div className="pb-8">
        <h3 className="pb-4 font-bold text-lg">Tour Details</h3>
        <dl className="flex flex-wrap text-sm">
          {tour.pickUp &&
            <>
              <dt className="w-1/2 font-bold border-b border-gray-200 py-3">Pick Up:</dt>
              <dd className="w-1/2 border-b border-gray-200 py-3 text-right">{tour.pickUp}</dd>
            </>
          }
          {tour.dropOff &&
            <>
              <dt className="w-1/2 font-bold border-b border-gray-200 py-3">Drop Off:</dt>
              <dd className="w-1/2 border-b border-gray-200 py-3 text-right">{tour.dropOff}</dd>
            </>
          }
          {(tour.locations && tour.locations.length !== 0) &&
            <>
              <dt className="w-1/2 font-bold border-b border-gray-200 py-3">Locations:</dt>
              <dd className="w-1/2 border-b border-gray-200 py-3 text-right">{tour.locations.length}</dd>
            </>
          }
          {tour.duration &&
            <>
              <dt className="w-1/2 font-bold border-b border-gray-200 py-3">Duration:</dt>
              <dd className="w-1/2 border-b border-gray-200 py-3 text-right">{tour.duration}hrs</dd>
            </>
          }
        </dl>
      </div>
    }
  </>
)

export default TourInfo